<template>
    <div>
        <base-header class="pb-1">
            <div class="row align-items-center py-4">
                <div class="col-lg-6 col-7">
                    <h6 class="h2 text-white d-inline-block mb-0">Relatório de Vidas PJ</h6>
                </div>
            </div>
        </base-header>
        <div class="container-fluid mt--6">
            <form>
                <card class="d-print-none">
                    <h3 slot="header" class="mb-0">Relatório de Vidas PJ</h3>
                    <div class="form-row mt-3">
                        <div class="col-md-4">
                            <base-input label="Empresa" required name="Empresa">
                                <el-select v-model="reportFilter.company" multiple filterable collapse-tags
                                    placeholder="Empresa">
                                    <el-option v-for="option in holders" :key="option.id" :label="option.value"
                                        :value="option.key" multiple>
                                        {{ option.value }}
                                    </el-option>
                                </el-select>
                            </base-input>
                        </div>
                        <div class="col-md-4">
                            <base-input label="Plano" required name="plano">
                                <el-select v-model="reportFilter.idplan" filterable placeholder="Plano">
                                    <el-option v-for="option in plans" :key="option.id" :label="option.name"
                                        :value="option.id">
                                        {{ option.name }}
                                    </el-option>
                                </el-select>
                            </base-input>
                        </div>
                        <div class="col-md-4">
                            <base-input label="Tipo de vida" required name="type">
                                <el-select v-model="reportFilter.dependentType" filterable placeholder="Tipo de vida">
                                    <el-option v-for="option in getListTypes()" :key="option.key" :label="option.value"
                                        :value="option.key">
                                        {{ option.value }}
                                    </el-option>
                                </el-select>
                            </base-input>
                        </div>
                        <div class="col-md-6">
                            <base-input addon-left-icon="ni ni-calendar-grid-58" label="Data Inicio">
                                <flat-picker slot-scope="{focus, blur}" @on-open="focus" @on-close="blur"
                                    :config="{ altInput: true, allowInput: true, dateFormat: 'Y-m-d', altFormat: 'd/m/Y' }"
                                    class="form-control datepicker" placeholder="Data Inicial"
                                    v-model="reportFilter.createdAtBegin">
                                </flat-picker>
                            </base-input>
                        </div>
                        <div class="col-md-6">
                            <base-input addon-left-icon="ni ni-calendar-grid-58" label="Data Final">
                                <flat-picker slot-scope="{focus, blur}" @on-open="focus" @on-close="blur"
                                    :config="{ altInput: true, allowInput: true, dateFormat: 'Y-m-d', altFormat: 'd/m/Y' }"
                                    class="form-control datepicker" placeholder="Data Final"
                                    v-model="reportFilter.createdAtEnd">
                                </flat-picker>
                            </base-input>
                        </div>
                    </div>
                    <div class="col-md-12 text-right mb-3">
                        <base-button type="secundary" native-type="button" @click="exportReport"><i
                                class="far fa-file-excel mr-1"></i>Exportar</base-button>
                        <base-button type="primary" native-type="button" @click="filterReport(1)"><i
                                class="fas fa-funnel-dollar mr-1"></i> Filtrar</base-button>
                    </div>
                </card>


                <card>
                    <h5 class="text-center mb-2">Encontradas <strong>{{ registryFound }}</strong> vidas</h5>
                    <table class="table font-tabela-peq flex">
                        <thead class="thead">
                            <tr>
                                <th scope="col">Assinatura</th>
                                <th scope="col">Club Card</th>
                                <th scope="col">Nome</th>
                                <th scope="col">Titular</th>
                                <th scope="col">Tipo</th>
                                <th scope="col">Data de inclusão</th>
                                <th scope="col">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="report in reportResult" :key="report.idclubcard">
                                <td><a href="javascript:void(0);" @click="openSubscription(report.subscriptionId)">{{
                                    report.subscriptionId }}</a></td>
                                <td>{{ formatCard(report.clubCardId.toString()) }}</td>
                                <td>{{ report.name }}</td>
                                <td>{{ report.holderId }}</td>
                                <td>{{ getTypeLabel(report.type) }}</td>
                                <td>{{ new Date(report.createdAt).toLocaleString() }}</td>
                                <td>{{ getStatusLabel(report.status) }}</td>
                            </tr>
                            <tr v-show="reportResult.length == 0">
                                <td colspan="8">
                                    <center> Nenhuma informação disponível no relatório para o período informado.
                                    </center>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="block" v-show="reportResult.length > 0">
                        <el-pagination layout="prev, pager, next" :total="registryFound"
                            @current-change="filterReport"></el-pagination>
                    </div>
                </card>
            </form>
        </div> <!--- fecha conteudo -->
    </div>
</template>

<script>
import { Select, Option, Pagination } from 'element-ui';
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import FileSaver from 'file-saver'

const lifeType = {
    'HOLDER': 'Titular',
    'DEPENDENT': 'Dependente'
}

const clubCardStatus = {
    'BLOCKED': 'BLOQUEADO',
    'OK': 'ATIVO',
    'CANCELED': 'CANCELADO',
    'EXPIRED': 'EXPIRADO',
    'BLOCKED_BY_FRAUD': 'BLOQUEADO POR FRAUDE',
};

export default {
    components: {
        [Select.name]: Select,
        [Option.name]: Option,
        flatPicker,
        [Pagination.name]: Pagination
    },
    created() {
        this.loadPlans();
        this.loadHolders();
    },
    data() {
        return {
            registryFound: 0,
            reportResult: [],
            reportFilter: {
                company: [],
                idplan: null,
                dependentType: null,
                createdAtBegin: null,
                createdAtEnd: null
            },
            plans: [],
            holders: [],
            page: 1,
            pageSize: 10
        }
    },
    methods: {
        exportReport() {
            const query = this.createQuery(false);

            this.$clubApi.get(`/report/lifes/export${query ? '?' : ''}${query}`, {
                responseType: 'blob'
            }).then((response) => {
                var blob = new Blob([response.data], {
                    type: 'text/csv'
                });
                FileSaver.saveAs(blob, 'relatorio-vidas-pj.csv');
            }).catch((error) => {
                this.$notify({ type: 'warning', message: error.response.data.msg })
            }).finally(() => {
                NProgress.done()
            })
        },
        filterReport(page) {
            this.page = page;

            const query = this.createQuery(true);

            this.$clubApi.get(`/report/lifes${query ? '?' : ''}${query}`)
                .then((response) => {
                    this.reportResult = response.data.object.items;
                    this.registryFound = response.data.object.count;
                }).catch((error) => {
                    this.$notify({ type: 'warning', message: error.response.data.msg })
                }).finally(() => {
                    NProgress.done()
                })
        },
        loadPlans() {
            return this.$clubApi.get('/plan/list/all/PJ')
                .then((response) => {
                    this.plans = response.data.object;
                })
                .catch((error) => this.notify(error.response.data.msg));
        },
        loadHolders() {
            return this.$clubApi.get('/holder/pj/company')
                .then((response) => {
                    this.holders = response.data.object;
                })
                .catch((error) => this.notify(error.response.data.msg));
        },
        notify(message) {
            this.$notify({ type: 'warning', message })
        },
        createQuery(includePage) {
            const parameters = [];

            const keys = Object.keys(this.reportFilter);

            for (let i = 0; i < keys.length; i++) {
                const element = this.reportFilter[keys[i]];

                if (element) {
                    if (element instanceof Array) {
                        for (let j = 0; j < element.length; j++) {
                            parameters.push([keys[i], element[j].toString() || '']);
                        }
                    }
                    else {
                        parameters.push([keys[i], element.toString()]);
                    }
                }
            }

            if (includePage) {
                parameters.push(['page', (this.page || 1).toString()]);
                parameters.push(['pageSize', (this.pageSize || 1).toString()]);
            }

            return new URLSearchParams(parameters).toString();
        },
        formatCard(value) {
            const v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '').padStart(16, '0');
            const matches = v.match(/\d{4,16}/g);
            const match = matches && matches[0] || ''
            const parts = []

            for (let i = 0, len = match.length; i < len; i += 4) {
                parts.push(match.substring(i, i + 4))
            }
            if (parts.length) {
                return parts.join(' ')
            } else {
                return value
            }
        },
        getListTypes() {
            const keys = Object.keys(lifeType);

            return keys.map(key => ({ key, value: lifeType[key] }));
        },
        getTypeLabel(key) {
            return lifeType[key];
        },
        getStatusLabel(key) {
            return clubCardStatus[key];
        },
        openSubscription(id) {
            location.href = '/assinatura/' + id;
        }
    }
};
</script>